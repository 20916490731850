import React from 'react';
import { render } from 'react-dom';
import { ready } from 'core-utilities';
import { EnvironmentUrls } from 'Roblox';
import { authenticatedUser } from 'header-scripts';
import LoginBase from './containers/LoginBase';
import {
  reactLoginPageContainer,
  reactWebAppLoginPageContainer
} from '../common/constants/browserConstants';
import '../../../css/login/login.scss';
import { getReturnUrl } from './utils/loginUtils';

function renderApp() {
  const entryPoint = reactWebAppLoginPageContainer() || reactLoginPageContainer();
  if (entryPoint) {
    if (entryPoint.id === 'react-login-web-app') {
      entryPoint.classList.add('login-container');
    }

    render(<LoginBase />, entryPoint);
  } else {
    // Recursively call renderApp if target div not found
    // Callback will be triggered before every repaint
    window.requestAnimationFrame(renderApp);
  }
}

ready(() => {
  // Don't render app and just redirect if U13
  if (authenticatedUser && authenticatedUser.isAuthenticated && authenticatedUser.isUnder13) {
    const returnUrl = getReturnUrl();
    window.location.href = returnUrl || `${EnvironmentUrls.websiteUrl}/home`;
    return;
  }

  renderApp();
});
